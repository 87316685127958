import "./styles/normalize.css";
import "./styles/animation.css";
import "./styles/fonts.css";
import "react-tooltip/dist/react-tooltip.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { MainContextProvider } from "./ContextState";
import ShowTimeWrapper from "./showTime/ShowTimeWrapper";
import MainPage from "./showTime/MainPage";

function App() {
    return (
        <MainContextProvider>
                <Router>
                    <Routes>
                        <Route element={ <ShowTimeWrapper/> }>
                            <Route path="/" element={ <MainPage/> }/>
                        </Route>
                    </Routes>
                </Router>
        </MainContextProvider>
    );
}

export default App;
