import axios from 'axios';

const API_URL = 'https://api.mebook.ai/api';
// const API_URL = 'https://qa-api.mebook.ai/api';

export const getBook = async (id) => {
    const response = await axios.get(`${API_URL}/editor/load?bookId=${id}`, {});
    return response.data.data;
};

export const verifyToken = async (token, bookId) => {
    const response = await axios.post(`${API_URL}/editor/verify`, {bookId, token});
    return response.data;
};

export const finishBook = async (bookId, token, bookData) => {
    const response = await axios.post(`${API_URL}/editor/approve-book`, {bookId, token, approvedData: bookData});
    return response.data.data;
};

export const saveBook = async (token, bookId, bookData) => {
    const response = await axios.post(`${API_URL}/editor/save-book`, {bookId, token, approvedData: bookData});
    return response.data.data;
};
