import styled from "styled-components";

export const ButtonStyled = styled.button`
    background: #FFA927;
    //background: rgb(39, 255, 196);
    color: #000;
    font-weight: 500;
    border-radius: 8px;
    display: flex;
    width: fit-content;
    border: none;
    cursor: pointer;
    line-height: 1.3;
    
    margin: auto;

    justify-content: center;
    padding: 8px 25px;
    font-size: 25px;
    align-items: start;

    &:disabled {
        background: #CFCBC4;
    }

    &[disabled] {
        background: #CFCBC4;
    }

    &:disabled:hover {
        cursor: default;
        background: #CFCBC4;
    }

    &:hover {
        background: #FFC43D;
    }
    
    &.isLoading{
        position: relative;
        
        &::after{
            content: "";
            position: absolute;
            width: 16px;
            height: 16px;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            border: 4px solid transparent;
            border-top-color: #ffffff;
            border-radius: 50%;
            animation: button-loading-spinner 1s ease infinite;
        }
        
        span{
            opacity: 0;
        }
    }

    @keyframes button-loading-spinner {
        from {
            transform: rotate(0turn);
        }

        to {
            transform: rotate(1turn);
        }
    }

    &.not_clickable{
        pointer-events: none;
    }
`;