import React, {useContext, useEffect, useRef, useState} from "react";
import {useLocation} from "react-router";
import {MainContext} from "../ContextState";
import {getBook} from "../ApiCalls";
import styled from "styled-components";
import Loader from "./components/Loader";
import ShowTimeList from "./components/ShowTimeList";


const MainPageStyled = styled.div`
    width: 100%;
    height: 100%;
`;

const NoBookIdStyled = styled.div`

`;

const MainPage = () => {
    const ref = useRef(null);
    const location = useLocation();
    const queryParameters = new URLSearchParams(location.search);
    const lang = queryParameters.get("language") || "he";
    const bookId = queryParameters.get("bookId") || "";

    const {bookDataContext} = useContext(MainContext);
    const [bookData, s_bookData] = bookDataContext;

    const [fontSize, s_fontSize] = useState(39);
    const [frameWidth, s_frameWidth] = useState(window.innerWidth);
    const [frameHeight, s_frameHeight] = useState(window.innerHeight);

    const [loading, s_loading] = useState(true);

    useEffect(() => {
        if (!bookData && bookId) {
            getBook(bookId).then(data => {
                if(data.status !== 'approved'){
                    const url = `https://editor.mebook.ai/?bookId=${bookId}`;
                    window.location.href = url;
                }else {
                    s_bookData(data);
                    s_loading(false);
                }
            });
        }
    }, []);

    useEffect(() => {
        const handleResize = () => {
            // Calculate font size based on window dimensions
            const newFontSize = Math.min(window.innerWidth, window.innerHeight);
            s_fontSize(newFontSize);
            s_frameHeight(window.innerHeight);
            s_frameWidth(window.innerWidth);
        };

        // Set initial font size
        handleResize();

        // Attach event listener for window resize
        window.addEventListener('resize', handleResize);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const noBookId = !bookId;
    const showData = bookId && !loading;

    return (
        <MainPageStyled
            ref={ref}
        >
            {noBookId && (
                <NoBookIdStyled>
                    No book id
                </NoBookIdStyled>
            )}
            {showData && (
                <ShowTimeList
                    lang={lang}
                    fontSize={fontSize}
                    frameWidth={frameWidth}
                    frameHeight={frameHeight}
                    pages={bookData.approvedData}
                    bookData={bookData}
                />
            )}
            {!noBookId && loading && (
                <Loader size={200}/>
            )}
        </MainPageStyled>
    );
};

export default MainPage;
