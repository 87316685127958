export const transformImage = (url, value) => {
    let result = url;
    if (url && value) {
        const wordAfter = "image/upload/";

        const parts = url.split(wordAfter);
        result = parts.join(wordAfter + value + "/");
    }
    return result;
};

export const getMobileQuery = () => {
    return window.innerWidth;
};

export const replaceUnsupportedImageExtension = (url) => {
    const supportedExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.webp'];
    const currentExtension = url.slice(url.lastIndexOf('.')).toLowerCase();
    if (!supportedExtensions.includes(currentExtension)) {
        // Replace the extension with '.jpg'
        const newUrl = url.slice(0, url.lastIndexOf('.')) + '.jpg';
        return newUrl;
    }
    return url;
};

// https://res.cloudinary.com/dqjknomcl/image/upload/l_text:Arial_24_bold:Colored%2520text,co_rgb:B90FAE/v1695824042/qdn2lre6glr8rlwjy2jo.png
export const addTextToCloudinaryImage = (url, coverDesign, templateTitle) => {
    let result = url;
    if (url && coverDesign && templateTitle) {
        let transedUrl = "";
        const splittedImage = url.split("/");

        const textSize = getFontSize(coverDesign.textSize);

        const color = coverDesign.textColor.replace('#', "");

        const value = `l_text:${coverDesign.fontName}_${textSize}_${coverDesign.fontWeight}:${templateTitle},co_rgb:${color},o_100,g_south,y_150/`;

        const len = splittedImage.length - 1;
        splittedImage.forEach((part, idx) => {
            if (part.includes('c_crop')) {
                transedUrl += part + '/' + value;
            } else {
                transedUrl += part;
                if (len !== idx) {
                    transedUrl += "/"
                }
            }
        });

        return transedUrl;
    }
    return result;
};

export function getFontSize(sizeName, partOfBook) {
    let size = 15;
    switch (sizeName) {
        case 'S':
            size = 2;
            break;
        case 'M':
            size = 2.5;
            break;
        case 'L':
            size = 3.5;
            break;
        case 'XL':
            size = 4;
            break;
    }
    if (partOfBook) {
        size = size + 0.5;
    }
    return size * 8
}

export function calculateFont(baseSize, width) {
    let fontSize = baseSize;
    const percent = width / 300 * 100; // cal
    const increaseFor = percent - 100;
    const plusNumber = fontSize / 100 * increaseFor;
    fontSize = fontSize + plusNumber;
    return Math.floor(fontSize);
}
