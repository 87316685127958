import React from "react";
import styled from "styled-components";


const WrapperStyled = styled.div`
    width: 100%;
    height: 100%;
`;

const TextStyled = styled.div`
    width: 80%;
    height: 100%;
    margin: auto;

    p {
        text-align: center;
        margin: auto;
        display: grid;
        align-content: center;
        font-size: 50px;
        line-height: 1.3;
        white-space: pre-line;
        width: 80%;
        height: 100dvh;
        font-family: "Varela Round", sans-serif;
        -webkit-font-smoothing: antialiased;

        @media (max-width: 768px) {
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            word-wrap: break-word;
            white-space: normal;
        }
    }
`;


const ShowTimeIteText = ({ page, fontSize }) => {
    return (
        <WrapperStyled>
            <TextStyled>
                <p
                    style={ { fontSize: fontSize / 22 } }
                >{ page.text }</p>
            </TextStyled>
        </WrapperStyled>
    );
};

export default ShowTimeIteText;
