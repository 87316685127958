import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { getMostCommonColor } from "../../common/imageColors";
import ColorThief from "colorthief";

const WrapperStyled = styled.div`
    width: 100%;
    height: 100dvh;
`;

const ImageStyled = styled.div`
    width: 100%;
    height: 100%;

    @media (max-width: 768px) {
        height: 100%;
    }

    img {
        width: 100%;
        object-fit: contain;
    }
`;

const ShowTimeItemImage = ({
                               page, frameWidth,
                               frameHeight
                           }) => {
    const [bgColors, s_bgColors] = useState("rbg(0,0,0)");

    useEffect(() => {
        const image = new Image();
        image.crossOrigin = "Anonymous"; // Enable CORS for the image

        image.onload = () => {
            const colorThief = new ColorThief();
            const color = colorThief.getColor(image);

            s_bgColors(`rgb(${ color[0] }, ${ color[1] }, ${ color[2] })`);
        };

        image.src = page.image;
    }, [page.image]);

    let imageWidth = "100%";
    let imageHeight = "100%";
    if (frameWidth > frameHeight) {
        imageHeight = frameHeight;
    } else {
        imageWidth = frameWidth;
    }

    return (
        <WrapperStyled
            style={ {
                backgroundColor: bgColors
            } }
        >
            <ImageStyled>
                <img
                    style={ {
                        width: imageWidth,
                        height: imageHeight
                    } }
                    src={ page.image } alt="image page"/>
            </ImageStyled>
        </WrapperStyled>
    );
};

export default ShowTimeItemImage;
