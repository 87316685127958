import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ButtonStyled } from "../../styles/CommonStyled";
import heb from "../../languages/heb";
import eng from "../../languages/eng";

const WrapperStyled = styled.div`
    z-index: 2;
    position: relative;
`;
const ShareButton = ({ lang, currentPage }) => {
    let langData = heb();
    if (lang === "en") {
        langData = eng();
    }

    const [isNativeShare, setNativeShare] = useState(false);
    useEffect(() => {
        if (navigator.share) {
            setNativeShare(true);
        }
    }, []);

    let canShare = false;
    if (isNativeShare && navigator && navigator.share && navigator.canShare && currentPage === 0) {
        canShare = true;
    }

    const share = () => {
        if (isNativeShare) {
            navigator.share({
                title: langData.share_book_title,
                text: "MeBook",
                url: window.location.href
            }).catch(err => console.error("Error sharing", err));
        }
    };

    return (
        <WrapperStyled>
            { canShare && (
                <ButtonStyled onClick={ share }>{ langData.share }</ButtonStyled>
            ) }
        </WrapperStyled>
    );
};

export default ShareButton;
