import {createContext, useState} from "react";

export const MainContext = createContext(null);

export const MainContextProvider = ({children}) => {
    const [lang, s_lang] = useState("he");
    const [bookData, s_bookData] = useState(null);

    return (
        <MainContext.Provider
            value={{
                bookDataContext: [bookData, s_bookData]
            }}
        >
            {children}
        </MainContext.Provider>
    );
};