import React from "react";
import styled from "styled-components";
import { Outlet, useLocation } from "react-router";

const MainWrapperStyled = styled.div`
    width: 100%;
    height: 100%;
`;

const ShowTimeWrapper = () => {
    const location = useLocation();
    const queryParameters = new URLSearchParams(location.search);
    const lang = queryParameters.get("language") || "he";

    let classes = "lang-he";
    if (lang !== "he") {
        classes = "lang-en";
    }

    return (
        <MainWrapperStyled className={ classes }>
            <Outlet/>
        </MainWrapperStyled>
    );
};

export default ShowTimeWrapper;
