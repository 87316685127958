import React from 'react';
import {Dna} from "react-loader-spinner";
import styled from "styled-components";

const LoaderWrapper = styled.div`
  text-align: center;
`;


const Loader = ({size = 80}) => {
    return (
        <LoaderWrapper>
            <Dna
                visible={true}
                height={size}
                width={size}
                ariaLabel="dna-loading"
                wrapperStyle={{}}
                wrapperClass="dna-wrapper"
            />
        </LoaderWrapper>
    );
};

export default Loader;